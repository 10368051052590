import { Component, inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { envs } from '@shared/envs/env';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  appVersion = envs.version;
  private matIconRegistry = inject(MatIconRegistry);

  ngOnInit(): void {
    this.matIconRegistry.setDefaultFontSetClass('mat-symbol-round');
  }
}
